import React, {Component} from "react";
import {Container} from "reactstrap";
import SectionTitle from "../../components/Shared/SectionTitle";

//Import Icons
import Feature from "../../components/Shared/Feature";
import pen from "../../assets/images/icon/pen.svg";
import video from "../../assets/images/icon/video.svg";
import intellectual from "../../assets/images/icon/intellectual.svg";
import user from "../../assets/images/icon/user.svg";
import calendar from "../../assets/images/icon/calendar.svg";
import sandClock from "../../assets/images/icon/sand-clock.svg";


export default class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      features: [
        {
          id: 1,
          icon: 'uil uil-edit-alt h1 text-primary',
          imgUrl: pen,
          title: "EXECUTIVE TRANSFERS",
          description:
            "We offer transfers from Airport to Hotel, Hotel to Airport, Home to office transfer between cities & towns",
        },
        {
          id: 2,
          icon: 'uil uil-vector-square h1 text-primary',
          imgUrl: video,
          title: "EXECUTIVE CORPORATE TRANSFERS",
          description: "We tailor our services for companies in diverse industries including corporates, international agencies and NGOs, Government agencies and a host of other companies. Our collection of vehicles will cater to your business meetings, conferences, VIP and VVIP transport, team buildings amongst other executive services."
        },
        {
          id: 3,
          icon: 'uil uil-file-search-alt h1 text-primary',
          imgUrl: intellectual,
          title: "CONFERENCES & BUSINESS MEETINGS",
          description:
            "We provide transport solutions for conferences & Business meetings. We take pride in delivering bespoke services to whether small or large delegations. This includes meet and greet and logistics control during the entire Event",
        },
        {
          id: 4,
          icon: 'uil uil-airplay h1 text-primary',
          imgUrl: user,
          title: "WEDDINGS",
          description:
            "Our collection of luxury cars will give your wedding that extra touch of class and royalty. We put great attention to detail to give you that memorable experience. From a large wedding to an intimate low key event for those close to you, count on us to meet your expectations.",
        },
      ],
    };
  }
  render() {
    return (
      <React.Fragment>
          <Container className={""}>
            <SectionTitle
              title="What We Do"
              desc="Learn about the wide range of services we offer."
            />
             <Feature featureArray={this.state.features} isCenter={true} />
          </Container>
      </React.Fragment>
    );
  }
}
