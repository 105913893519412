import React, {Component} from "react";
import {Col, Container, Row} from "reactstrap";
import CountUp from "react-countup";

export default class Client extends Component {
  constructor(props) {
    super(props);
    this.state = {
      counter: [1000, 1402],
    }
  }
  render() {
    return (
      <React.Fragment>
        <Container className="">
          <Row>
            <Col lg={6}>
              <div className="section-title text-center text-lg-start">
                <h4 className="title mb-4">
                  Trusted by over <br />{" "}
                  <span className="text-primary">10,000+</span>clients around the world.
                </h4>
                <p className="text-muted mb-0 mx-auto para-desc">

                </p>
              </div>
            </Col>

            <Col lg={6}>
              <Row>
                <Col md={6} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                  <div className="content text-center">
                    <h1 className="mb-0">
                      <span className="counter-value" >
                        <CountUp end={1000} duration={8} />
                      </span>
                      +
                    </h1>
                    <ul className="list-unstyled mb-0 h5">
                      <li className="list-inline-item">
                        <i className="uil uil-heart text-danger"></i>
                      </li>
                      <li className="list-inline-item">
                        <i className="uil uil-heart text-danger"></i>
                      </li>
                      <li className="list-inline-item">
                        <i className="uil uil-heart text-danger"></i>
                      </li>
                      <li className="list-inline-item">
                        <i className="uil uil-heart text-danger"></i>
                      </li>
                      <li className="list-inline-item">
                        <i className="uil uil-heart text-danger"></i>
                      </li>
                    </ul>
                    <h6>Trusted Users</h6>
                  </div>
                </Col>

                <Col md={6} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                  <div className="content text-center">
                    <h1 className="mb-0">
                      <span className="counter-value">
                      <CountUp end={97} duration={8} />
                      </span>
                      %
                    </h1>
                    <ul className="list-unstyled mb-0 h5">
                      <li className="list-inline-item">
                        <i className="mdi mdi-star text-warning"></i>
                      </li>
                      <li className="list-inline-item">
                        <i className="mdi mdi-star text-warning"></i>
                      </li>
                      <li className="list-inline-item">
                        <i className="mdi mdi-star text-warning"></i>
                      </li>
                      <li className="list-inline-item">
                        <i className="mdi mdi-star text-warning"></i>
                      </li>
                      <li className="list-inline-item">
                        <i className="mdi mdi-star text-warning"></i>
                      </li>
                    </ul>
                    <h6>Happy Clients</h6>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
