import React from "react";
import OurServices from "./pages/LandingOne/Services";
import AboutUs from "./pages/LandingOne/AboutUs";
import ContactUs from "./pages/LandingOne/ContactUs";

const LandingOne = React.lazy(() => import("./pages/LandingOne/index"));

const routes = [
    {path: "/services", component: OurServices, isTopbarDark: true},
    {path: "/about", component: AboutUs, isTopbarDark: true},
    {path: "/contact", component: ContactUs, isTopbarDark: true},

    {path: "/", component: LandingOne, exact: true},
    {component: LandingOne, isWithoutLayout: true, exact: false},
];

export default routes;
