import React, {useState} from "react";
import PageLayout from "../../components/Shared/PageLayout";
import {Alert, Card, CardBody, Col, Container, Form, Input, Label, Row} from "reactstrap";
import {Link} from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import contact from "../../assets/images/contact.svg";


const ContactUs = () => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const sendMail = () => {
        window.location.href = "mailto:info@jobiexecutive.co.ke";
    }

    const callNumber = () => {
        window.location.href = "tel:+254 725 110 114";
    }

    const sendWAMessage = () => {
        if (!name || !email || !message) {
            return
        }

        const phoneTo = "++254725110114"
        const parsedMessage = `
        Name: ${name}
        Email: ${email}
        
        ${message}
        `

        window.open(`https://api.whatsapp.com/send?phone=${phoneTo}&text=${parsedMessage}`)
    }

    return (
        <PageLayout title={"Make Enquiry"} subTitle={""}>

            <section className="section">
                <Container>
                    <Row>
                        <Col md={6}>
                            <Card className="border-0 text-center features feature-clean">
                                <div className="icons text-primary text-center mx-auto">
                                    <i className="uil uil-phone d-block rounded h3 mb-0"></i>
                                </div>
                                <div className="content mt-3">
                                    <h5 className="fw-bold">Phone</h5>
                                    <p className="text-muted">
                                        Call us today
                                    </p>
                                    <Link
                                        to="#"
                                        onClick={callNumber}
                                        className="text-primary"
                                    >
                                        +254 725 110 114
                                    </Link>
                                </div>
                            </Card>
                        </Col>

                        <Col md={6} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <Card className="border-0 text-center features feature-clean">
                                <div className="icons text-primary text-center mx-auto">
                                    <i className="uil uil-envelope d-block rounded h3 mb-0"></i>
                                </div>
                                <div className="content mt-3">
                                    <h5 className="fw-bold">Email</h5>
                                    <p className="text-muted">
                                        Send an email
                                    </p>
                                    <Link
                                        to="#"
                                        onClick={sendMail}
                                        className="text-primary"
                                    >
                                        info@jobiexecutive.co.ke
                                    </Link>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Container className="mt-100 mt-60">
                    <Row className="align-items-center">
                        <Col
                            lg={5}
                            md={6}
                            className="mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1"
                        >
                            <Card className="shadow rounded border-0">
                                <CardBody className="py-5">
                                    <h4 className="card-title">Get In Touch !</h4>
                                    <div className="custom-form mt-3">

                                        <Row>
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">
                                                        Your Name <span className="text-danger">*</span>
                                                    </Label>
                                                    <div className="form-icon position-relative">
                                                        <i>
                                                            <FeatherIcon
                                                                icon="user"
                                                                className="fea icon-sm icons"
                                                            />
                                                        </i>
                                                    </div>
                                                    <Input
                                                        name="name"
                                                        id="name"
                                                        type="text"
                                                        className="form-control ps-5"
                                                        placeholder="Your name"
                                                        required
                                                        value={name}
                                                        onChange={e => setName(e.target.value)}
                                                    />
                                                </div>
                                            </Col>
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">
                                                        Your Email{" "}
                                                        <span className="text-danger">*</span>
                                                    </Label>
                                                    <div className="form-icon position-relative">
                                                        <i>
                                                            <FeatherIcon
                                                                icon="mail"
                                                                className="fea icon-sm icons"
                                                            />
                                                        </i>
                                                    </div>
                                                    <Input
                                                        name="email"
                                                        id="email"
                                                        type="email"
                                                        className="form-control ps-5"
                                                        placeholder="Your email :"
                                                        required
                                                        value={email}
                                                        onChange={e => setEmail(e.target.value)}
                                                    />
                                                </div>
                                            </Col>

                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Your Message
                                                        <span className="text-danger">*</span>
                                                    </Label>
                                                    <div className="form-icon position-relative">
                                                        <i>
                                                            <FeatherIcon
                                                                icon="message-circle"
                                                                className="fea icon-sm icons"
                                                            />
                                                        </i>
                                                    </div>
                                                    <textarea
                                                        name="comments"
                                                        id="comments"
                                                        rows="4"
                                                        className="form-control ps-5"
                                                        placeholder="Your Message :"
                                                        value={message}
                                                        onChange={e => setMessage(e.target.value)}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} className="text-center">
                                                <div className="d-grid">
                                                    <button
                                                        onClick={sendWAMessage}
                                                        className="submitBnt btn btn-primary"
                                                    >
                                                        Send Message
                                                    </button>
                                                </div>
                                                <div id="simple-msg"></div>

                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={7} md={{size: 6, order: 2}} xs={{order: 1}}>
                            <Card className="border-0">
                                <CardBody className="p-0">
                                    <img src={contact} className="img-fluid" alt="Landrick"/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>

        </PageLayout>
    )
}

export default ContactUs
