import React, {Component} from "react";
import {Container} from "reactstrap";
import SectionTitle from "../../components/Shared/SectionTitle";
import {Link} from "react-router-dom";
import logodark from "../../assets/images/logo-dark.png";

export default class About extends Component {

    render() {
        return (
            <React.Fragment>
                <Container className={"mt-60"}>
                    <div className={'text-center mb-4'}>
                        <Link to="#" className="logo-footer align-center">
                            <img
                                src={logodark}
                                height="200"
                                alt=""
                                className={"rounded"}
                            />
                        </Link>
                    </div>


                    <SectionTitle
                        title="Who We Are"
                        desc={"At Jobi Executive, we redefine executive car hires and transfers, offering a premium and tailored experience for discerning clients. Whether it's airport transfers, corporate events, or VIP transportation needs, we pride ourselves on delivering the epitome of luxury, comfort, and professionalism. Our fleet of meticulously maintained vehicles, coupled with experienced chauffeurs, ensures that every journey is executed with precision and style. Elevate your travel experience with us, where executive sophistication meets seamless transfers, setting a new standard in executive car services."}
                    />
                </Container>
            </React.Fragment>
        );
    }
}
