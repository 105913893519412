// React Basic and Bootstrap
import React, {Component} from 'react';
import {Container} from 'reactstrap';

//Import Components
import SectionTitle from "../../components/Shared/SectionTitle";
import ReviewsSlider from "../../components/Shared/ReviewsSlider";

//Import Images
import img1 from "../../assets/images/client/01.jpg";
import img2 from "../../assets/images/client/02.jpg";
import img3 from "../../assets/images/client/03.jpg";
import img4 from "../../assets/images/client/04.jpg";
import img5 from "../../assets/images/client/05.jpg";
import img6 from "../../assets/images/client/06.jpg";

// Blog Images
import blog1 from '../../assets/images/blog/01.jpg';
import blog2 from '../../assets/images/blog/02.jpg';
import blog3 from '../../assets/images/blog/03.jpg';

class Testi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reviews: [
                {
                    id: 1,
                    img: img1,
                    name: "James Thompson",
                    post: "C.E.O",
                    desc: "We've been sourcing our raw materials from Border Stone, and they've never let us down. Their dedication to ethical mining practices and environmental responsibility aligns perfectly with our values. Plus, their team is always ready to provide excellent customer support and expert advice.",
                    rating: 5
                },
                {
                    id: 2,
                    img: img2,
                    name: "James Thompson",
                    post: "M.D",
                    desc: "When it comes to reliability and consistency in the mining industry, Border Stone is second to none. We've built a long-standing partnership with them, and they've consistently provided us with high-quality materials at competitive prices. Their dedication to safety in mining operations is commendable.",
                    rating: 5
                },
                {
                    id: 3,
                    img: img3,
                    name: "Michael Chang",
                    post: "P.A",
                    desc: "As a global construction company, we rely on partners who can meet our demanding requirements. Border Stone consistently exceeds our expectations with their prompt deliveries, product quality, and dedication to responsible mining practices. They're a vital part of our supply chain.",
                    rating: 5
                },
                // {
                //     id: 4,
                //     img: img4,
                //     name: "Christa Smith",
                //     post: "Manager",
                //     desc: "According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero.",
                //     rating: 5
                // },
                // {
                //     id: 5,
                //     img: img5,
                //     name: "Dean Tolle",
                //     post: "Developer",
                //     desc: "There is now an abundance of readable dummy texts. These are usually used when a text is required.",
                //     rating: 5
                // },
                // {
                //     id: 6,
                //     img: img6,
                //     name: "Jill Webb",
                //     post: "Designer",
                //     desc: "Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts.",
                //     rating: 4
                // },
            ],
            blogs: [
                {
                    id: 1,
                    image: blog1,
                    title: "Design your apps in your own way",
                    like: "33",
                    comment: "08",
                    autor: "Calvin Carlo",
                    date: "13th August, 2019"
                },
                {
                    id: 2,
                    image: blog2,
                    title: "How apps is changing the IT world",
                    like: "33",
                    comment: "08",
                    autor: "Calvin Carlo",
                    date: "13th August, 2019"
                },
                {
                    id: 3,
                    image: blog3,
                    title: "Smartest Applications for Business",
                    like: "33",
                    comment: "08",
                    autor: "Calvin Carlo",
                    date: "13th August, 2019"
                },
            ]
        }
    }

    render() {
        return (
            <React.Fragment>
                <section className="section bg-light">
                    <Container>
                        {/* section title */}
                        <SectionTitle
                            title="Client Reviews"
                            desc="See examples of our past projects and success stories across various industries. Our
                                    portfolio showcases our commitment to delivering top-notch solutions tailored to our
                                    clients' unique needs."/>

                        {/* clients slider */}
                        <ReviewsSlider reviews={this.state.reviews} colClass="mt-4"/>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default Testi;
