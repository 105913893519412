import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Container, Row, Col, Breadcrumb, BreadcrumbItem} from "reactstrap";
import Colors from "../../utils/Colors";

class PageBreadcrumb extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="bg-half bg-overlay w-100"
                         style={{paddingTop: 120, paddingBottom: 40, backgroundColor: Colors.grey_100}}>
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg="12" className="text-center">
                                <div className="page-next-level">
                                    {this.props.title ? (
                                        <h4 className="title fw-bold"> {this.props.title}</h4>
                                    ) : null}
                                    <span className={"text-muted"}>{this.props.subTitle}</span>
                                    {/*{this.props.children ? this.props.children : null}*/}
                                    {/*<div className="page-next">*/}
                                    {/*  <nav className="d-inline-block">*/}
                                    {/*    <Breadcrumb*/}
                                    {/*      aria-label="breadcrumb"*/}
                                    {/*      listClassName="bg-white rounded shadow mb-0"*/}
                                    {/*    >*/}
                                    {/*      {this.props.pathItems.map((item, key) =>*/}
                                    {/*        item.id !== length ? (*/}
                                    {/*          <BreadcrumbItem key={key}>*/}
                                    {/*            <Link*/}
                                    {/*              to={item.link}*/}
                                    {/*              className="text-uppercase fw-bold text-dark me-1"*/}
                                    {/*            >*/}
                                    {/*              {item.name}*/}
                                    {/*            </Link>*/}
                                    {/*          </BreadcrumbItem>*/}
                                    {/*        ) : (*/}
                                    {/*          <BreadcrumbItem*/}
                                    {/*            key={key}*/}
                                    {/*            active*/}
                                    {/*            aria-current="page"*/}
                                    {/*          >*/}
                                    {/*            {item.name}*/}
                                    {/*          </BreadcrumbItem>*/}
                                    {/*        )*/}
                                    {/*      )}*/}
                                    {/*    </Breadcrumb>*/}
                                    {/*  </nav>*/}
                                    {/*</div>*/}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default PageBreadcrumb;
