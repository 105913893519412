import React from "react";
import PageLayout from "../../components/Shared/PageLayout";
import Features from "./Features";
import Client from "./Client";
import Testi from "./Testi";


const OurServices = () => {


    return (
        <PageLayout title={"Our Services"} subTitle={""}>

            <section className="section">
                <Features/>
            </section>

            <section className="section">
                <Client/>
            </section>

            <section className="section">
                <div className="position-relative">
                    <div className="shape overflow-hidden text-light">
                        <svg
                            viewBox="0 0 2880 48"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                                fill="currentColor"
                            ></path>
                        </svg>
                    </div>
                </div>
                <Testi/>
            </section>

        </PageLayout>
    )
}

export default OurServices
